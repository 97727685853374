<template>
    <div class="page page-view">
        <div class="basic">
        <el-form
            v-loading="loading"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            v-show="basicInfoViewFlag"
        >
            <div class="basic-form basic-form-view">
                <div class="view-title">
                    <div class="view-flag"></div>
                    <div class="title">个人信息</div>
                </div>
                <div class="baseMain">
                    <div class="rt">
                        <el-row>
                            <el-col :span="12">
                                <el-row>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-checked">
                                            登录账号
                                        </div>
                                    </el-col>
                                    <el-col :span="16">
                                        <div class="baseCol baseCol-first">
                                            {{ ruleForm.loginName }}
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="8">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            登录密码
                                        </div>
                                    </el-col>
                                    <el-col :span="16">
                                        <div class="baseCol baseCol-first baseCol-top">
                                            <template v-if="ruleForm.password && ruleForm.password.length > 0">
                                                 <span
                                                     v-for="ps in ruleForm.password
                                                    .length"
                                                     :key="ps"
                                                 >●</span>
                                            </template>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="8">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            用户角色
                                        </div>
                                    </el-col>
                                    <el-col :span="16">
                                        <div class="baseCol baseCol-second">
                                            <span
                                                class="post-item"
                                                v-for="roleName in showRoleList"
                                                :key="roleName"
                                                >{{ roleName }}</span
                                            >
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="12">
                                <el-row>
                                    <el-col :span="8">
                                        <div
                                            class="baseCol baseCol-img baseCol-checked"
                                        >
                                            头像
                                        </div>
                                    </el-col>
                                    <el-col :span="16">
                                        <div class="lt">
                                            <el-image
                                                class="showImage"
                                                :src="ruleForm.headImg || uploadAvatar"
                                                :preview-src-list="[ruleForm.headImg || uploadAvatar]"
                                            >
                                            </el-image>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-top baseCol-checked"
                                >
                                    姓名
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    {{ ruleForm.teacherName }}
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-second baseCol-checked"
                                >
                                    手机号
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div
                                    :style="
                                        ruleForm.phone.indexOf('*') === -1
                                            ? {
                                                  color: '#3672d9',
                                              }
                                            : {}
                                    "
                                    class="baseCol baseCol-second"
                                >
                                    {{ ruleForm.phone }}
                                    <i
                                        v-has-permi="[
                                            'teacherBase:desensitized',
                                        ]"
                                        class="el-icon-view"
                                        :class="{'is-closed': ruleForm.phone.indexOf('*') > 0}"
                                        :style="
                                            ruleForm.phone.indexOf('*') === -1
                                                ? {
                                                      color: '#3672d9',
                                                  }
                                                : {}
                                        "
                                        @click="desensitizeGet"
                                    ></i>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-top baseCol-checked"
                                >
                                    工号
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    {{ ruleForm.onlyCode }}
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-second baseCol-checked"
                                >
                                    性别
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    <div v-if="ruleForm.sex == 1">男</div>
                                    <div v-else>女</div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-top baseCol-checked"
                                >
                                    任职部门
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    <template v-if="ruleForm.orgList.length">
                                        <span
                                            v-for="item in ruleForm.orgList"
                                            :key="item.id"
                                            class="post-item"
                                            >{{ item.name }}</span
                                        >
                                    </template>
                                    <template v-else>
                                        <div></div>
                                    </template>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-second baseCol-checked"
                                >
                                    是否任教
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    <div v-if="ruleForm.isSubjectTeacher == 1">
                                        是
                                    </div>
                                    <div v-else>否</div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-top baseCol-checked"
                                >
                                    班主任
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    <div v-if="ruleForm.isHeadmaster == 1">
                                        是
                                    </div>
                                    <div v-else>否</div>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-second baseCol-checked"
                                >
                                    负责班级
                                    <el-tooltip class="item" effect="dark" content="班主任负责班级" placement="top-start">
                                        <i class="el-icon-question"></i>
                                    </el-tooltip>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    <template
                                        v-if="ruleForm.manageClassList.length"
                                    >
                                        <el-popover
                                            v-if="
                                                ruleForm.manageClassList
                                                    .length > 6
                                            "
                                            placement="top-start"
                                            title="班主任负责班级"
                                            width="200"
                                            trigger="hover"
                                        >
                                            <span
                                                v-for="item in ruleForm.manageClassList"
                                                :key="item.id"
                                                class="post-item"
                                                >{{ item.name }}</span
                                            >
                                            <div
                                                slot="reference"
                                                class="refer"
                                            >
                                                ...
                                            </div>
                                        </el-popover>
                                        <span
                                            v-for="(
                                                cl, c
                                            ) in ruleForm.manageClassList"
                                            :key="c"
                                            class="post-item"
                                            >{{ cl.name }}</span
                                        >
                                    </template>
                                    <template v-else>
                                        <div></div>
                                    </template>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-top baseCol-checked"
                                >
                                    班级权限
                                    <el-tooltip class="item" effect="dark" content="班级数据权限，可查看哪些班级的数据。" placement="top-start">
                                        <i class="el-icon-question"></i>
                                    </el-tooltip>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    <template v-if="ruleForm.viewList.length">
                                        <el-popover
                                            v-if="ruleForm.viewList.length > 13"
                                            placement="top-start"
                                            title="可查看班级数据"
                                            width="200"
                                            trigger="hover"
                                        >
                                            <span
                                                v-for="item in ruleForm.viewList"
                                                :key="item.id"
                                                class="post-item"
                                                >{{ item.name }}</span
                                            >

                                            <div
                                                slot="reference"
                                                class="refer"
                                            >
                                                ...
                                            </div>
                                        </el-popover>
                                        <span
                                            v-for="item in ruleForm.viewList"
                                            :key="item.id"
                                            class="post-item"
                                            >{{ item.name }}</span
                                        >
                                    </template>
                                    <template v-else>
                                        <div></div>
                                    </template>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-second baseCol-checked"
                                >
                                    任教科目
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    <span
                                        v-for="(sub, subi) in subJectList"
                                        :key="subi"
                                        class="post-item"
                                        >{{ sub }}</span
                                    >
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-top baseCol-checked"
                                >
                                    职务
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    <template v-if="showPostList.length">
                                        <span
                                            v-for="(
                                                post, posti
                                            ) in showPostList"
                                            :key="posti"
                                            class="post-item"
                                            >{{ post }}</span
                                        >
                                    </template>
                                    <template v-else>
                                        <div></div>
                                    </template>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-second baseCol-checked"
                                >
                                    入职时间
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="baseCol baseCol-second">
                                    <span v-if="ruleForm.entryTime">{{
                                        ruleForm.entryTime.substring(0, 10)
                                    }}</span>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-top baseCol-checked"
                                >
                                    序列号
                                </div>
                            </el-col>
                            <el-col :span="20">
                                <div class="baseCol baseCol-second">
                                    <span>{{ ruleForm.serialNumber }}</span>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="4">
                                <div
                                    class="baseCol baseCol-top baseCol-checked"
                                >
                                    部门权限
                                </div>
                            </el-col>
                            <el-col :span="20">
                                <div class="baseCol baseCol-second">
                                    <template
                                        v-if="
                                            ruleForm.powerOrganList &&
                                            ruleForm.powerOrganList.length > 0
                                        "
                                    >
                                        <span
                                            v-for="power in ruleForm.powerOrganList"
                                            :key="power.id"
                                            class="post-item"
                                            >{{ power.name }}</span
                                        >
                                    </template>
                                    <template v-else> </template>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="archivesCompleteness">
                    <img
                        :src="archivesCompletenessUrl"
                        class="archivesCompleteness-icon"
                        alt="icon"
                    />
                    <div class="archivesCompleteness-title">档案填写进度</div>
                    <div class="archivesCompleteness-value">
                        {{ ruleForm.archivesCompleteness }}%
                    </div>
                </div>
                <el-progress
                    :percentage="ruleForm.archivesCompleteness"
                    :show-text="false"
                ></el-progress>
            </div>
        </el-form>
        <div
            v-if="oriBasicForm.id && moreInfoViewFlag"
            class="detail-form"
        >
            <StuInfoFill
                ref="stuInfoFill"
                type="teacher"
                :segmentIds="segmentIds"
                :mode="query.pageType"
                :school-id="query.schoolId"
                :stu-basic-info="oriBasicForm"
                @getTemplateList="getTemplateList"
            />
        </div>
    </div>
    <dialog-image :dialogImageObj="dialogImageObj"  @closed="closedBtn" ></dialog-image>
    </div>
</template>
<script>
import { StuInfoFill } from "common-local";
import DialogImage from "@/components/scrollWrapper/Sub/DialogImage.vue";
export default {
    name: "SummaryTeacherView",
    components: {
        StuInfoFill,
        DialogImage
    },
    props: {
        loading: Boolean,
        ruleForm: Object,
        rules: Object,
        showRoleList: Array,
        subJectList: Array,
        showPostList: Array,
        oriBasicForm: Object,
        query: Object,
        segmentIds: Array,
        basicInfoViewFlag: Boolean,
        moreInfoViewFlag: Boolean
    },
    data() {
        return {
            dialogImageObj: {
                show: false,
                url: ""
            }
        }
    },
    computed: {
        action() {
            return "/api/school/common/uploadFile";
        },
        uploadAvatar() {
            return require('@/assets/images/imgErrorAdr.png')
        },
        archivesCompletenessUrl() {
            return require("@/assets/images/archivesCompleteness.png");
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    created() {
        console.log(33333333333333,this.segmentIds, this.basicInfoViewFlag, this.moreInfoViewFlag)
    },
    methods: {
        bigShow(url){
            this.dialogImageObj.url = url;
            this.dialogImageObj.show = true;
        },
        closedBtn(){
            this.dialogImageObj = {
                show: false,
                url: ""
            }
        },
        desensitizeGet () {
            this.$emit('desensitizeGet')
        },
        getTemplateList (val) {
            this.$emit('getTemplateList', val)
        }
    }
};
</script>
<style lang="scss" scoped>
.page {
    padding: 16px;
    height: calc(100vh - 140px);
    // display: flex;
    // flex-direction: column;
    overflow-y: auto;
}
.exportDialog {
    margin-top: 10px;

    .exportDialog-title {
        display: flex;
        align-items: center;

        .exportDialog-text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #363a40;
            margin-right: 16px;
        }

        .exportDialog-reset {
            cursor: pointer;
            width: 72px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #e8f1fc;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3C7FFF;
        }
    }

    .exportDialog-content {
        width: 544px;
        background: #ffffff;
        border: 1px solid #edeff2;
        border-radius: 2px;
        margin-top: 20px;

        .exportDialog-content-title {
            display: flex;
            line-height: 40px;
            background: #f5f7fa;

            .exportDialog-content-sort {
                width: 80px;
                border-right: 1px solid #edeff2;
                text-align: center;
            }

            .exportDialog-content-name {
                flex: 1;
                padding-left: 20px;
                box-sizing: border-box;
            }
        }
        .exportDialog-content-title-sort {
            display: flex;
            line-height: 40px;
            .exportDialog-content-sort-title {
                width: 80px;
                border-right: 1px solid #edeff2;
                border-bottom: 1px solid #edeff2;
                text-align: center;
            }

            .exportDialog-content-name-text {
                flex: 1;
                padding-left: 20px;
                box-sizing: border-box;
                border-bottom: 1px solid #edeff2;
            }
        }
    }

    .exportDialog-list {
        display: flex;
        background-color: #fff;
        height: 40px;
        line-height: 40px;

        .exportDialog-sort {
            width: 80px;
            height: 40px;
            line-height: 40px;
            border-right: 1px solid #edeff2;
            border-bottom: 1px solid #edeff2;
            text-align: center;

            &:last-child {
                border-bottom: 0;
            }
        }

        .exportDialog-name {
            flex: 1;
            height: 40px;
            line-height: 40px;
            display: flex;
            border-bottom: 1px solid #edeff2;
            position: relative;

            .exportDialog-name-info {
                margin-left: 20px;
            }

            .exportDialog-icon {
                position: absolute;
                right: 20px;
                top: 14px;
                display: flex;

                .move-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 26px;
                    display: block;
                    cursor: pointer;
                }

                .del-icon {
                    cursor: pointer;
                    width: 12px;
                    height: 12px;
                    display: block;
                }
            }
        }
    }

    .exportDialog-info {
        margin-top: 20px;
        height: 14px;
        line-height: 14px;
        display: flex;

        .dialog-info-icon {
            width: 13px;
            height: 13px;
        }

        .dialog-info-text {
            margin-left: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f56c6c;
        }
    }

    .dialog-footer {
        display: flex;
        // margin: 0 auto;
        margin-top: 26px;

        .dialog-cancel,
        .dialog-submit {
            width: 120px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border: 1px solid #3C7FFF;
            border-radius: 4px;
            color: #3C7FFF;
            cursor: pointer;
        }

        .dialog-submit {
            background: #3C7FFF;
            color: #ffffff;
            margin: 0 24px 0 140px;
        }
    }
}

.archivesCompleteness {
    width: 100%;
    height: 40px;
    line-height: 40px;
    // border: 1px solid black;
    background: #edf4fa;
    margin-top: 32px;
    display: flex;
    align-items: center;

    .archivesCompleteness-icon {
        display: inline-block;
        margin-left: 12px;
        width: 13px;
        height: 8px;
    }

    .archivesCompleteness-title {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6c84a6;
        margin: 0 10px;
    }

    .archivesCompleteness-value {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3C7FFF;
    }
}
.newRoleList {
    width: 100% !important;
}

// 公共-有分割线的标题
::v-deep .line-title {
    text-align: center;
    display: flex;
    flex-direction: row;
}

::v-deep .line-title:before {
    content: "";
    flex: 1 1;
    width: 200px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}

::v-deep .line-title:after {
    content: "";
    flex: 1 1;
    width: 200px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}

// 公共-按钮
.btn {
    width: 88px;
    height: 36px;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
}

.btn-reset {
    background: #ebf1f7;
    color: #737373;
}

.btn-confirm {
    background: #3C7FFF;
    color: #ffffff;
}

// 基础信息
.basic {
    width: 100%;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px;
}

// 基础信息-头部
.basic-header,
.basic-header-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .basic-header-flag {
        width: 1px;
        height: 16px;
        background: #bfbfbf;
        margin: 0 16px 0 12px;
    }

    .basic-title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .exportBtn {
        margin-left: 0;
    }
}

.basic-header-view {
    width: calc(100% - 20px);
    position: absolute;
    top: 0px;
    left: 0px;
    height: 52px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-bottom: 1px solid #e8e8e8;
}

// 基础信息-表单
.basic-form,
.basic-form-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 56px 10px 40px 40px;

    .baseTitle {
        width: 100%;
        text-align: left;
        // border-bottom: 1px solid black;
    }

    .baseMain {
        display: flex;
    }

    .lt {
        /deep/.el-upload {
            text-align: left;
        }

        .upload-img {
            width: 104px;
            height: 104px;
            border-radius: 6px;
        }
    }

    .rt {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            padding-left: 40px;
            box-sizing: border-box;
            width: 50%;

            /deep/ .el-form-item__label {
                padding-right: 0;
            }

            /deep/ .el-select {
                width: 100%;
            }
        }

        .div-divider {
            height: 1px;
            width: 100%;
            margin: 10px 0 24px 40px;
            background: #edf0f2;
        }
    }
}

.basic-form-view {
    display: block;
    padding: 0;
    margin-top: 50px;

    // border: 1px solid black;
    .rt {
        width: 100%;
        display: block;
    }

    .lt {
        height: 124px;
        border: 1px solid #919599;
        border-left: 1px solid rgba(255, 255, 255, 0);

        .upload-img {
            display: block;
            height: 104px;
            width: 104px;
            border-radius: 8px;
            margin: 10px auto 0;
        }
    }
}

.tac {
    text-align: center;
}

// 展示或收起按钮
.show-stow-btn {
    margin-top: 10px;
    height: 48px;
    background: #f7fafc;
    border-radius: 6px 6px 0px 0px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3C7FFF;
    cursor: pointer;

    img {
        width: 12px;
        height: 8px;
        margin-left: 8px;
        margin-bottom: 2px;
    }
}

// 组件组
::v-deep .comps-group-div {
    margin-top: 22px;

    .comps-group-item {
        padding: 0;
    }
}

// 更多信息表
.detail-form {
    background-color: #ffffff;
    border-radius: 0px 0px 6px 6px;
    // flex: 1;
}

.baseCol {
    border: 1px solid #919599;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #363b40;
    min-height: 42px;
    padding: 0 12px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.refer {
    position: absolute;
    top: 0;
    left: 302px;
    width: 20px;
    font-size: 14px;
    color: #363b40;
    // border: 1px solid black;
}

.baseCol-first {
    border-left: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-secondTitle {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-top: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-second {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-top: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-right {
    border-right: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-top {
    border-top: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-img {
    border-left: 1px solid rgba(255, 255, 255, 0);
    height: 126px;
    line-height: 126px;
    text-align: center;
}

.baseCol-checked {
    background-color: #edf4fa;
}

.view-title {
    width: 100%;
    height: 14px;
    line-height: 14px;
    display: flex;
    margin: 20px 0 16px;

    .view-flag {
        width: 3px;
        height: 14px;
        background-color: #3C7FFF;
        margin-right: 8px;
    }

    .title {
        font-size: 16px;
        font-weight: bold;
    }
}

.post-item {
    margin: 0 5px;
}
.page-view {
    .basic {
        position: relative;
        margin: 0 auto;
        width: 1000px !important;
    }

    ::v-deep .stu-info-fill-main {
        padding: 0 0 24px 0 !important;
    }

    ::v-deep .el-form-item {
        margin-bottom: 0;
        border: 1px solid black;

        .explainText {
            display: none;
        }

        .el-checkbox-group {
            // box-sizing: border-box;
            overflow-y: scroll;
        }

        .el-radio-group {
            width: 100%;
            // padding-top: 10px;
            line-height: 47px !important;
            height: 36px;
            overflow-y: scroll;
        }

        .el-form-item__label {
            text-align-last: justify; // 最后一行两端对齐
            text-align: justify; // 两端对齐
            padding: 0 12px;
            width: 166px !important;
            // box-sizing: border-box;
            // background-color:#edf4fa;
        }

        .el-form-item__content {
            border-left: 1px solid black;
            // height: 36px;
            min-height: 36px;
            position: relative;
            margin-left: 166px !important;

            .explain {
                text-align: center !important;
                font-size: 14px !important;
                color: #363b40 !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .form-value-show-only,
            .el-radio,
            .el-checkbox-group,
            .explain {
                padding-left: 15px;
            }

            .el-checkbox-group {
                height: 36px;
            }

            .el-radio-group {
                height: 36px;
            }

            .upload-demo {
                padding-left: 5px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .avatar-uploader {
                padding: 5px 10px;
            }

            .input-div {
                position: absolute;
                height: 100%;

                .input {
                    .el-input__inner {
                        font-size: 14px;
                        color: #363b40;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    /deep/ .el-table--border,
    .el-table--group {
        border: 1px solid #919599 !important;
    }

    /deep/ .el-table th.el-table__cell {
        color: #363b40 !important;
        font-size: 14px;
        font-weight: 400;
        background-color: #edf4fa !important;
        border: 1px solid #919599 !important;
        border-top: 1px solid rgba(255, 255, 255, 0) !important;

        &:nth-child(n) {
            border-left: 1px solid rgba(255, 255, 255, 0) !important;
        }
    }

    /deep/ .el-table__body td.el-table__cell {
        border: 1px solid #919599 !important;
        border-top: 1px solid rgba(255, 255, 255, 0) !important;
        font-size: 14px;
        color: #363b40;

        &:nth-child(n) {
            border-left: 1px solid rgba(255, 255, 255, 0) !important;
            // border-bottom: 1px solid #919599 !important;
        }
    }

    /deep/ .el-form-item {
        border: 1px solid #919599 !important;
        height: inherit !important;

        .el-input.is-disabled .el-input__inner {
            background-color: white !important;
            text-align: center;
        }

        .el-textarea.is-disabled .el-textarea__inner {
            background-color: white !important;
            font-size: 14px;
            color: 363b40;
        }

        .el-form-item__label {
            text-align: center;
            text-align-last: unset;
            font-size: 14px;
            color: #363b40;
            background-color: #edf4fa;
        }

        .el-form-item__content {
            border-left: 1px solid #919599 !important;
        }

        .avatar-uploader-icon {
            width: 148px !important;
            height: 148px !important;
            line-height: 148px !important;
        }

        .avatar-uploader {
            margin-top: 21px !important;
            // overflow: unset !important;
        }

        .el-upload {
            cursor: not-allowed !important;
        }

        // .el-input__inner{
        //   background-color: white !important;
        // }
    }
}
</style>
<style lang="scss">
.page-view .el-input__inner::placeholder {
    color: white !important;
}

.page-view .el-textarea__inner::placeholder {
    color: white !important;
}
.showImage{
    width: 104px;
    height: 104px;
    display: block;
    margin: 10px auto;
}

.el-icon-view {
    cursor: pointer;
    position: relative;
}

.el-icon-view.is-closed::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #606266;
    transform: rotate(-45deg);
    top: -1px;
    left: 50%;
}
</style>

<template>
    <div
        class="common-form-main common-form-layout-scroll"
    >
        <div
            class="detail-form common-form-view"
        >
            <stu-info-fill
                ref="stuInfoFill"
                type="teacher"
                :mode="query.pageType"
                :school-id="query.schoolId"
                :stu-basic-info="oriBasicForm"
                :templateList="templateList"
                :selectedTemplate="selectedTemplate"
                @getTeacherBaseInfo="getTeacherBaseInfo"
            ></stu-info-fill>
        </div>
    </div>
</template>
<script>
import StuInfoFill from './stuInfoTemplate/Fill.vue';
export default {
    name: "SummaryTeacherMoreForm",
    components: {
        StuInfoFill
    },
    props: {
        oriBasicForm: Object,
        ruleForm: Object,
        query: Object,
        templateList: Array,
        selectedTemplate: Number
    },
    methods: {
        getTeacherBaseInfo () {
            this.$emit('getTeacherBaseInfo')
        },
    }
};
</script>
<style lang="scss" scoped>
    .common-form-main {
        .common-form-view {
            text-align: left;
        }
    }
</style>
